import React from 'react';
import { TrendingUp, BarChart, Clock, Shield } from 'lucide-react';

const Hero2 = () => {
  return (
    <div className="pt-24 pb-16 bg-gradient-radial from-emerald-50 via-white to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-6" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>
            {/* Power Your Pricing Strategy with RateMatePro */}
            No More Guesswork—Outsmart Competitor Pricing in Real Time with RateMatePro
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>
            {/* Real-time pricing for airports and city locations, with customizable strategies to maximize your revenue. */}
            In the fast-paced car rental industry, staying competitive is everything. RateMatePro tracks real-time market rates and dynamically adjusts your pricing—so you always win on price, maximize revenue, and boost bookings effortlessly
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <button className="btn-primary font-semibold" style={{fontFamily: "'Inter', system-ui, sans-serif", fontSize: "0.99rem"}} onClick={() => {
                window.location.href = '/contact'
                // window.location.href =
                //   "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WSw1qjrR2hccl9DJLPHj-Ysh9yS4VBQkXcPCVtSwIcoqaT-m2aaiiG1tQRT0IcJEmRHDEEPJh?gv=true";
              }}>  Learn More </button>
          </div>
          {/* <div className="flex flex-wrap justify-center gap-4">
            <button className="btn-primary" style={{fontFamily: "'Inter', system-ui, sans-serif", fontSize: "0.99rem"}}>Book a Demo</button>
          </div> */}
        </div>

        <div className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="feature-card">
            <TrendingUp className="h-10 w-10 text-emerald-500 mb-4" />
            <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Real-Time Rates</h3>
            <p className="text-gray-600" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Dynamic pricing updates for airports and city locations in real-time.</p>
          </div>
          
          <div className="feature-card">
            <Clock className="h-10 w-10 text-emerald-500 mb-4" />
            <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Custom Strategies</h3>
            <p className="text-gray-600" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Create and deploy custom pricing strategies tailored to your business needs.</p>
          </div>
          
          <div className="feature-card">
            <Shield className="h-10 w-10 text-emerald-500 mb-4" />
            <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Market Intelligence</h3>
            <p className="text-gray-600" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Stay competitive with real-time market insights and demand analysis.</p>
          </div>
          
          <div className="feature-card">
            <BarChart className="h-10 w-10 text-emerald-500 mb-4" />
            <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Location Optimization</h3>
            <p className="text-gray-600" style={{ fontFamily: "'Inter', system-ui, sans-serif" }}>Optimize rates across different locations based on local market conditions.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero2;