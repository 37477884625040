import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { NavLink } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PolicyIcon from '@mui/icons-material/Policy';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import TextField from '@mui/material/TextField';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import SearchIcon from '@mui/icons-material/Search';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PercentIcon from '@mui/icons-material/Percent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RuleIcon from '@mui/icons-material/Rule';
import AddBoxIcon from '@mui/icons-material/AddBox';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import RedeemIcon from '@mui/icons-material/Redeem';
// import BlockIcon from '@mui/icons-material/Block';
import StarIcon from '@mui/icons-material/Star';
import TuneIcon from '@mui/icons-material/Tune';
import InventoryIcon from '@mui/icons-material/Inventory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CodeIcon from '@mui/icons-material/Code';
import GarageIcon from '@mui/icons-material/Garage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EuroIcon from '@mui/icons-material/Euro';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import PreviewIcon from '@mui/icons-material/Preview';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import RouteIcon from '@mui/icons-material/Route';
import StorageIcon from '@mui/icons-material/Storage';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import CameraIcon from '@mui/icons-material/Camera';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { baseURL } from '../pages/backend_url';
import axios from 'axios';
import PublishIcon from '@mui/icons-material/Publish';
import ComputerIcon from '@mui/icons-material/Computer';
import CommuteIcon from '@mui/icons-material/Commute';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import PsychologyIcon from '@mui/icons-material/Psychology';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import BoltIcon from '@mui/icons-material/Bolt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';



export default function Sidebar({ setCollapsedFlag, sidebarColor }) {

  const [searchedMenu, setSearchedMenu] = useState([]);
  const [search, setSearch] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [selectFavourites, setSelectFavourites] = useState(false);
  const [moduleDetail, setModuleDetail] = useState([]);
  const [menuItem, setMenuItem] = useState([]);
  const [userRoleDetail, setUserRoleDetail] = useState("");
  const [menuItemsFinal, setMenuItemsFinal] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const[currentSubMenu, setCurrentSubMenu] = useState()
  const[subMenuName, setSubMenuName] = useState()
  const[subHeader, setSubHeader] = useState("")

  const iconHash = {
    "Dashboard": <DashboardIcon />,
    "Account": <AccountBoxIcon />,
    "Account Locations": <AddLocationAltIcon />,
    "User": <GroupIcon />,
    "Location": <EditLocationAltIcon />,
    "Business Hours": <WorkHistoryIcon />,
    "Policies": <PolicyIcon />,
    "Fees and Taxes": <PercentIcon />,
    "Optional Services": <AddBoxIcon />,
    "Fleet SetUp": <DirectionsCarIcon />,
    "One Way Rental": <AddRoadIcon />,
    "Reservation Source": <EventSeatIcon />,
    "Rate Code": <CurrencyExchangeIcon />,
    "Account Rate Code": <RateReviewIcon />,
    "Account Rate Rules": <RuleIcon />,
    "Minimum Rate": <KeyboardCapslockIcon />,
    "Instant Automation Pro": <BoltIcon />,
    "Instant Rate Shop": <FlashAutoIcon />,
    "Adjustment": <EqualizerIcon />,
    "Promotion": <RedeemIcon />,
    // "Blackout": <BlockIcon />,
    "Update Inventory": <InventoryIcon />,
    "Utilization Based Adjustment": <TuneIcon />,
    "Error Codes": <CodeIcon />,
    "Access Setup": <ManageAccountsIcon />,
    "Cars": <GarageIcon />,
    "Reports": <SummarizeIcon />,
    "Europcars": <EuroIcon />,
    "Capacity Control": <MinorCrashIcon />,
    "Reservations": <BookOnlineIcon />,
    "No Show": <BrowserNotSupportedIcon />,
    "Fetch Console": <VideogameAssetIcon />,
    "Rate ID": <RouteIcon />,
    "Event Log": <StorageIcon />,
    "Optional Services Standard": <AirlineSeatLegroomReducedIcon />,
    "Fleet Standard": <NoCrashIcon />,
    "Rate Code Mapping": <NoCrashIcon />,
    "Rate Snapshot": <PreviewIcon />,
    "Optional Services Images": <CameraIcon />,
    "Affiliate OTA Mapping" : <AccountTreeIcon />,
    "Bank Reconciliation Report": <AccountBalanceIcon />,
    "Multiple Accounts Adjustment & Promotion" : <ContentCopyIcon />,
    "Commission Screen": <PublishIcon />,
    "Competitor SetUp": <CommuteIcon />,
    "Competitor Mapping": <PriceCheckIcon />,
    "Min / Max Settings": <PriceCheckIcon />,
    "Automation Plan": <PriceCheckIcon />,
    "Enable Automation": <ChecklistIcon />,
    "Automation Scheduler": <ComputerIcon />,
    "Currency Converter": <CurrencyExchangeIcon />,
    "Support Screen" : <ContactSupportIcon />,
    "Report SetUp" : <TextSnippetIcon />,
    "Rate Download" : <SystemUpdateAltIcon />,
    "Competitors" : <PreviewIcon />,
    "Activity Queue" : <PreviewIcon />,
    "Locations" : <AddLocationIcon />,
    "Master" : <PreviewIcon />,
    "Rate Correction" : <PreviewIcon />,
    "TESTING" : <PreviewIcon />,
    "TESTING" : <PreviewIcon />,
    "Market Insight Location" : <PreviewIcon />,
    "Rate Insights" : <PreviewIcon />,
    "Custom Strategy" : <PsychologyIcon />,
  }

  const handleSubMenu2 =(menuName) =>{
   console.log("im here")
   setShowSubMenu(true);
  //  console.log(subHeader)
  //  console.log(menuName)
    // console.log(menuName.target.innerHTML)
  }

  const handleSubMenu = (menuName) => {
    console.log(menuName)
    if (showSubMenu  == menuName){
      setShowSubMenu(false);
    }else{
      setShowSubMenu(menuName);
    }
    
    // console.log(menuName.target.innerHTML)
    
    // setCurrentSubMenu(menuName.target.innerHTML)
    // if (showSubMenu == currentSubMenu){
    //   setShowSubMenu(false);
    // }else{
    //   setShowSubMenu(true);
    // }
  }

  useEffect(() => {
    axios.get(baseURL + "/get_modules")
      .then((res) => {

        const menuItems = res.data.map((val1) => {

          let temp_mod_name1 = val1.name.toString().trim();
          let subModuleExists = val1.hasOwnProperty('sub_module');
          let sub_module = subModuleExists ? val1.sub_module : null;
          
          if (temp_mod_name1 === "Dashboard") {
            temp_mod_name1 = ""
          }
          else {
            if (temp_mod_name1.indexOf(' ') > -1) {
              let t1_split = temp_mod_name1.split(" ");
              if (t1_split.length > 0) {
                temp_mod_name1 = '';
                for (let i1 = 0; i1 < t1_split.length; i1++) {

                  temp_mod_name1 += t1_split[i1];
                }
              }
            }
          }

          return { "name": val1.name, "icon": iconHash[val1.name],"submenu": sub_module, "routing": '/' + temp_mod_name1, "favourite": val1.favourite }
        })


        setModuleDetail(res.data);
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        console.log('foundUser',foundUser)
        setUserEmail(foundUser.userEmail);
        setUserID(foundUser.userID)

        if (foundUser.role === 'Users') {
        // if (foundUser === 'Users') {

          axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
            .then((res1) => {
              let accnt = foundUser.account_id
              if(foundUser.account_type.account_type != 'Corporate'){

                accnt = foundUser.account_type.affiliated_to_id
              }
           
              axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role , 'account_id': accnt.toString()})
                .then((res2) => {
                  console.log("menuItems")
                  console.log(menuItems)
                  const tempMenuItems = menuItems.filter((val, i) => {

                    let temp_mod_name = val.name.toString().trim();
                    if (temp_mod_name.indexOf(' ') > -1) {
                      let t1_split = temp_mod_name.split(" ");
                      if (t1_split.length > 0) {
                        temp_mod_name = '';
                        for (let i1 = 0; i1 < t1_split.length; i1++) {

                          temp_mod_name += t1_split[i1];
                        }
                      }
                    }
                    // console.log(val.name)
                    // console.log("first",res2.data[0].selected_access)
                    // console.log("iiiiiii")
                    // console.log("second",res2.data[0].selected_access[val.name])
                    // console.log("jjjjjjjj")
                    // console.log("third",res2.data[0].selected_access[temp_mod_name]['View'])

                    // if (res2.data[0].selected_access && res2.data[0].selected_access[val.name] && 'View' in res2.data[0].selected_access[val.name]) {
                    //   // If the key 'View' exists, access its value
                    //   var viewValue = res2.data[0].selected_access[val.name]['View'];
                    //   if (viewValue == true){
                    //       console.log("value:",val.name)
                    //       return val
                    //   }
                      
                    //   // console.log("Value of 'View':", viewValue);
                    // } else {
                    //     // console.log("Key 'View' does not exist in the object or the object is undefined.");
                    // }

                    // if ( (temp_mod_name === 'SupportScreen') || res2.data[0].selected_access[temp_mod_name + "_Access Specifier"] === "Edit"
                    //   || res2.data[0].selected_access[temp_mod_name + "_Access Specifier"] === "View") {
                        
                    //   return val
                    // }

                    if ( (temp_mod_name === 'SupportScreen') || (temp_mod_name === 'ViewBaseRates' && (res2.data[0].role_definition["RateUpdate_Access Specifier"] === "Edit"
                      || res2.data[0].role_definition["RateUpdate_Access Specifier"] === "View")) || res2.data[0].role_definition[temp_mod_name + "_Access Specifier"] === "Edit"
                        || res2.data[0].role_definition[temp_mod_name + "_Access Specifier"] === "View") {
                        return val
                      }
                  })
                  console.log("tempMenuItems:",tempMenuItems)

                  setMenuItemsFinal(tempMenuItems)

                })
            })
        }
        else if (foundUser.role === 'SuperAdmin') {
        // else if (foundUser === 'SuperAdmin') {

          const tempMenuItems = menuItems;

          setMenuItemsFinal(tempMenuItems)

        }

        let arr = [];
        let arr2 = [];
        // arr.push({ name: 'Dashboard', icon: iconHash[i], routing: '/', favourite: false })
        res.data.map((val, i) => {
          // arr.push({ name: val.name, icon: iconHash[i], routing: '/'+val.name, favourite: false });
          arr2.push(false);
        }
        )

        setMenuItem(arr2);
      });
  }, [])


  useEffect(() => {
    setSearchedMenu(menuItemsFinal.filter(item => item.name.toLowerCase().includes(search.toLowerCase())));
  }, [search])

  //Updating Favourites
  const changeFavourite = (val, email) => {

    let tempMenuItems = [...menuItemsFinal]
    for (let i = 0; i < tempMenuItems.length; i++) {
      if (tempMenuItems[i].name === val.name) {

        tempMenuItems[i].favourite[email] = !tempMenuItems[i].favourite[email]
      }
    }

    axios.put(baseURL + "/update_favourite/" + val.name, {
      "favourite": val.favourite
    })
      .then((res2) => {
        setMenuItemsFinal(tempMenuItems);
      })
  }

  // FDB840 - orange 
  // 54ADD1 - blue
  // 8b5a3d - brown
  // 96b85d - olive green
  // 563826 - dark brown
  return (
    <div className="sidebar" style={{ backgroundColor: sidebarColor === "" ? "" : sidebarColor }}>
      <div className="sidebarWrapper" style={{backgroundColor: 'black'}}>
      {/* <div className="sidebarWrapper" style={{backgroundColor: '#54ADD1'}}> */}
        <div className="sidebarMenu"> 

          {/* <button  onClick={() => setCollapsed(!collapsed)}/>  */}
          <ul className={`sidebarList ${collapsed && 'collapsed'}`}>

            <li className="sidebarListItem1 ">
              {collapsed ? (
                <ChevronRightRoundedIcon onClick={() => {
                  setCollapsed(!collapsed)
                  setCollapsedFlag(!collapsed)
                }} fontSize='large' style={{ cursor: "pointer", color:"white" }} />
              ) :
                (
                  <KeyboardArrowLeftRoundedIcon onClick={() => {
                    setCollapsed(!collapsed)
                    setCollapsedFlag(!collapsed)
                  }} fontSize='large' style={{ cursor: "pointer" }} />
                )
              }
            </li>

            <li className="mb-2 mt-2">
              {collapsed ?
                <><SearchIcon onClick={() => setCollapsed(!collapsed)} className="sidebarListItem" fontSize='large'  /></>
                : <TextField id="outlined-basic" label="Search Modules" style={{backgroundColor:'white',color:'black'}} variant="outlined" size="small" onChange={(e) => setSearch(e.target.value)}
                />}
            </li>

            <li className="mb-3 mt-3">
              {collapsed ? (
                <BookmarksIcon onClick={() => setCollapsed(!collapsed)} className="sidebarListItem" fontSize='large' />
              ) : (<>
                <div
                  style={{
                    width: '50%', float: 'left', textAlign: 'center', cursor: 'pointer', padding: 5,
                    // backgroundColor: selectAll ? 'white' : "", color: selectAll ? 'orange' : 'orange'
                    backgroundColor: selectAll ? 'white' : "", color: selectAll ? 'green' : 'green'
                  }}
                  onClick={() => { setSelectAll(selectFavourites); setSelectFavourites(selectAll) }}
                >
                  <strong>All</strong>
                </div>
                <div style={{
                  width: '50%', float: 'right', textAlign: 'center', cursor: 'pointer', padding: 5,
                  // backgroundColor: selectFavourites ? 'white' : "", color: selectFavourites ? 'orange' : 'orange'
                  backgroundColor: selectFavourites ? 'white' : "", color: selectFavourites ? 'green' : 'green'
                }}
                  onClick={() => { setSelectAll(selectFavourites); setSelectFavourites(selectAll) }}
                >
                  <strong>Favourites</strong>
                </div>
                <br />
              </>)}
            </li>

            {

              !selectFavourites ? (search ? searchedMenu.map((val, i) => (<>
                {val.submenu && (val.submenu).length > 0 ? (
                <>
                  <NavLink className={showSubMenu ? 'link-active' : 'link'} style={{backgroundColor:'yellow',color:'black'}}>
                    <li className="sidebarListItem" style={{ float: 'left', maxWidth: 200}}>
                      <div onClick={ (e) => handleSubMenu(val.name)}>
                      {val.icon}  <span>{val.name}</span>
                        {showSubMenu === val.name ? (<FaChevronUp style={{marginLeft:"12px"}}/>) : (<FaChevronDown style={{marginLeft:"12px"}}/>)}</div>
                        {showSubMenu === val.name && (
                          <ul className="sidebarList sidebar_sub_list">
                          {val.submenu.map((submenuItem, index) => (
                            <NavLink to={submenuItem.name} className={({ isActive }) => (isActive ? 'link-active2' : 'link')}>
                              <li className="sidebarListItem2" style={{ float: 'right'}}>
                                {submenuItem.icon} <span>{submenuItem.name}</span>
                              </li>
                            </NavLink>
                          ))}
                          </ul>
                        )}
                    </li>
                    
                  </NavLink>
                  </>
                ):(
                <NavLink to={val.routing} className={({ isActive }) => (isActive ? 'link-active' : 'link')}>
                  <li className="sidebarListItem" style={{ float: 'left', maxWidth: 200 }}>
                    {val.icon} {!collapsed && <>&nbsp;&nbsp;<span>{val.name}</span></>}
                  </li>
                  <li style={{ float: 'right', maxWidth: 20 }}>{val.favourite !== undefined && val.favourite[userID] ? (<StarIcon fontSize='small' onClick={() => changeFavourite(val, i)}  />)
                    : (<StarBorderIcon fontSize='small'  onClick={() => changeFavourite(val, userID)} />)}
                  </li>
                </NavLink>
                )}
                </>
              )) :
                menuItemsFinal.map((val, i) => (<>
                {val.submenu && (val.submenu).length > 0 ? (
                  <>
                  <NavLink className={showSubMenu ? 'link-active' : 'link'} style={{backgroundColor:'black',color:'black'}}>
                    <li className="sidebarListItem3" style={{ float: 'left', maxWidth: 200,color:'white'}}>
                      <div onClick={(e) => handleSubMenu(val.name)}>
                      {val.icon}  <span>{val.name}</span>
                        {showSubMenu === val.name ? (<FaChevronUp style={{marginLeft:"12px"}}/>) : (<FaChevronDown style={{marginLeft:"12px"}}/>)}</div>
                        {showSubMenu === val.name && (
                          <ul className="sidebarList sidebar_sub_list">
                          {val.submenu.map((submenuItem, index) => (
                            <NavLink to={submenuItem.routing} className={({ isActive }) => (isActive ? 'link-active2' : 'link')}>
                              <li className="sidebarListItem2" style={{ float: 'right',color:'white'}}>
                                {submenuItem.icon} <span style={{fontSize:'14px'}}>{submenuItem.name}</span>
                              </li>
                            </NavLink>
                          ))}
                          </ul>
                        )}
                    </li>
                  </NavLink>
                  
                  </>
                ):(
                  <NavLink to={val.routing} className={({ isActive }) => (isActive ? 'link-active' : 'link')}>

                    <li className="sidebarListItem" style={{ float: 'left', maxWidth: 200 }}>
                      {val.icon} {!collapsed && <>&nbsp;&nbsp;<span>{val.name}</span></>}
                    </li>
                    {!collapsed &&
                      <li style={{ float: 'right', maxWidth: 20 }}>{val.favourite !== undefined && val.favourite[userID] ? (<StarIcon fontSize='small'  onClick={() => changeFavourite(val, userID)} />)
                        : (<StarBorderIcon fontSize='small'  onClick={() => changeFavourite(val, userID)} />)}</li>
                    }
                  </NavLink>
                )}
                </>
                ))
              )
                : (search ? searchedMenu.map((val, i) => (
                  val.favourite[userID] && (
                    <NavLink to={val.routing} className={({ isActive }) => (isActive ? 'link-active' : 'link')}>
                      <li className="sidebarListItem" style={{ float: 'left', maxWidth: 200 }}> 
                        {val.icon} {!collapsed && <>&nbsp;&nbsp;<span>{val.name}</span></>}
                      </li>
                    </NavLink>
                  )
                )) :
                  menuItemsFinal.map((val, i) => val.favourite[userID] && (<>
                    <NavLink to={val.routing} className={({ isActive }) => (isActive ? 'link-active' : 'link')}>

                      <li className="sidebarListItem" style={{ float: 'left', maxWidth: 200 }}>
                        {val.icon} {!collapsed && <>&nbsp;&nbsp;<span>{val.name}</span></>}
                      </li>
                      {!collapsed &&
                        <li style={{ float: 'right', maxWidth: 20 }}>{val.favourite !== undefined && val.favourite[userID] ? (<StarIcon fontSize='small'  onClick={() => changeFavourite(val, userID)} />)
                          : (<StarBorderIcon fontSize='small'  onClick={() => changeFavourite(val, userID)} />)}</li>
                      }
                    </NavLink>

                  </>
                  ))
                )
            }
          </ul>
        </div>
      </div>
    </div>
  )
}